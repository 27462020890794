<template>
  <div
    class="mem-checkbox"
    :class="{
      'is-checked': isChecked,
      'theme-light': theme === 'light',
      'theme-dark': theme === 'dark',
      'has-error': hasError,
    }"
  >
    <i class="material-icons" v-if="isChecked"> check </i>
    <input type="checkbox" v-bind="$attrs" v-model="model" />
  </div>
</template>
<script>
// TODO: Make fixed size
export default {
  data: () => ({}),
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: "dark",
    },
  },
  computed: {
    isChecked() {
      return this.value;
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.mem-checkbox {
  display: flex;
  min-height: 20px;
  min-width: 20px;
  color: white;
  position: relative;
  // border-radius: 2px;
  margin-top: 4px;
  &.theme-dark {
    border: 2px solid #343434;
    i {
      color: #121212;
    }
  }
  &.theme-light {
    border: 2px solid #c2c2c2;
    i {
      color: #ffffff;
    }
  }
  &.has-error {
    border: 2px solid #ff0707;
  }
  &.is-checked {
    border: none;
    &.theme-dark {
      background-color: #ffffff;
    }
    &.theme-light {
      background-color: #121212;
    }
  }
  i {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 20px;
  }
  input[type="checkbox"] {
    position: absolute;
    display: flex;
    opacity: 0;
    height: 100%;
    width: 100%;
  }
}
</style>